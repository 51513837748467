
import { Vue, Options } from "vue-class-component";
import { AuthController } from "@/app/ui/controllers/AuthController";
import {
  getAccessRemember,
  getExpired,
  getToken,
  getTokenMiddleware,
  setHubData
} from "@/app/infrastructures/misc/Cookies";
import Input from "@/app/ui/components/input/index.vue";
import Footer from "@/app/ui/components/footer/index.vue";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import {
  IS_SENDER_ACCOUNT,
  validatePhoneNumberAdvanced
} from "@/app/infrastructures/misc/Utils";
import router from "../../router";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { HubController } from "../../controllers/HubController";
import { AccountController } from "../../controllers/AccountController";
import { Hub } from "@/domain/entities/Hub";
import { flags } from "@/feature-flags";
import { FirebaseController } from "@/app/ui/controllers/FirebaseController";
import { PairTokenRequest } from "@/data/payload/api/AccountRequest";

@Options({
  name: "LoginPage",
  components: {
    Input,
    Footer
  }
})
export default class Login extends Vue {
  get loginModuleData() {
    if (IS_SENDER_ACCOUNT) {
      return {
        logo: "images/logo-lion-parcel.png",
        type: "text",
        title: "Akun<br/>Lion Parcel",
        prefix: "+62",
        placeholderEmail: "Contoh: 81289826272",
        placeholderPassword: "Masukan password",
        isErrorEmail:
          this.isEmailEmpty || this.invalidEmail || this.isErrorEmail,
        emailErrorMessage: this.emailErrorMessage,
        dialogData: {
          title: "Akses Lupa Password Melalui Aplikasi",
          message:
            "Akses fitur lupa password Anda melalui aplikasi Lion Parcel",
          image: "account-pin-lock",
          textSuccess: "Tutup",
          onClose: () => (this.isForgotPassword = false)
        }
      };
    }
    return {
      logo: "svg/logo-genesis.svg",
      type: "text",
      title: "Genesis",
      prefix: "",
      placeholderEmail: "Input email or username",
      placeholderPassword: "Input password",
      isErrorEmail: this.isEmailEmpty || this.invalidEmail || this.isErrorEmail,
      emailErrorMessage: this.emailErrorMessage,
      dialogData: {
        title: "Your Account is Inactive",
        message: `Account with user name ${this.email} is inactive or cannot be used, please contact Lion Parcel CS for more info`,
        image: "inactive-account",
        textSuccess: "Back",
        onClose: this.handleErrorClose
      }
    };
  }

  onFormatEmailPhone(value: string) {
    if (IS_SENDER_ACCOUNT) {
      return value.replaceAll(/\D+/g, "");
    }
    return value;
  }
  onInvalidInput() {
    this.isEmailEmpty = !this.email;
  }
  onInputEmail(value: string) {
    AuthController.setErrorWrongEmail(false);
    this.isEmailEmpty = false;
    this.invalidEmail = false;

    if (IS_SENDER_ACCOUNT) {
      this.invalidEmail = !validatePhoneNumberAdvanced(
        this.loginModuleData.prefix + value
      );
    }
  }
  onInputPassword() {
    AuthController.setErrorPassword(false);
  }

  rememberMe = getAccessRemember();
  email = !this.rememberMe.email ? "" : this.rememberMe.email;
  password = !this.rememberMe.password ? "" : this.rememberMe.password;
  isEmailEmpty = false;
  invalidEmail = false;
  invalidPassword = false;
  isForgotPassword = false;

  get hubList() {
    return HubController.hubList.data;
  }

  get popupLogout() {
    return new ModalMessageEntities({
      image: "are-you-sure",
      title: "Are you sure you want to change your account?",
      message: "You will repeat the login process again",
      textSuccess: "Change Account",
      textCancel: "Stay Here",
      onSubmit: () => this.showPopupToLogout(),
      onClose: () => (this.showPopupLogout = false)
    });
  }

  get popupErrorHub() {
    return new ModalMessageEntities({
      image: "image-modal-failed",
      title: "Failed to get the Hub List!",
      textSuccess: "OK",
      onSubmit: () => HubController.setError(false)
    });
  }

  async showPopupToLogout() {
    await AuthController.logout();
    this.showPopupLogout = false;
    this.setShowHubList(false);
  }

  get isHubError() {
    return HubController.isError;
  }

  showPopupLogout = false;

  onClickHub(key: any) {
    this.redirectPageHomeConsole();
    setHubData(key);
    this.setShowHubList(false);
    FirebaseController.getTokenFirebase()
      .then(currentToken => {
        AccountController.pairToken(
          new PairTokenRequest(
            currentToken,
            AccountController.accountData.account_id
          )
        );
      })
      .catch(err => {
        console.log(err);
      });
  }

  redirectPageHomeConsole() {
    if (AccountController.accountData.accountIsForeign) {
      router.replace("/shipment-rates");
    } else {
      router.replace(flags.flag_default_path_genesis.getValue());
    }
  }

  unChooseHub() {
    setHubData(new Hub());
    this.$router.replace(flags.flag_default_path_genesis.getValue());
    this.setShowHubList(false);
    FirebaseController.getTokenFirebase()
      .then(currentToken => {
        AccountController.pairToken(
          new PairTokenRequest(
            currentToken,
            AccountController.accountData.account_id
          )
        );
      })
      .catch(err => {
        console.log(err);
      });
  }

  get username() {
    return AccountController.accountData.username;
  }

  setShowHubList(param: boolean) {
    AuthController.setShowHubList(param);
  }

  onForgotPassword() {
    if (IS_SENDER_ACCOUNT) {
      this.isForgotPassword = true;
    } else {
      router.push("/account/forgot-password");
    }
  }

  //Error Internet or System
  get isErrorInternetOrSystem() {
    return AuthController.isErrorInternetOrSystem;
  }
  //Error Cause
  get errorCause() {
    return AuthController.errorCause;
  }

  get showHubList() {
    return AuthController.showHubList;
  }

  handleErrorClose() {
    AuthController.handleError();
  }

  get isErrorEmail() {
    return AuthController.isErrorWrongEmail;
  }
  get emailErrorMessage() {
    if (IS_SENDER_ACCOUNT) {
      if (this.isEmailEmpty) {
        return "Masukan no. handphone anda";
      } else if (this.invalidEmail) {
        return "Masukan no. handphone dengan benar";
      } else if (this.isErrorEmail) {
        return AuthController.emailErrorMessage;
      }
    } else {
      if (this.invalidEmail || this.isEmailEmpty) {
        return "Input your email or username";
      } else if (this.isErrorEmail) {
        return "Your email or username is incorrect. Check or try again";
      }
    }
    return "";
  }
  get errorPassword() {
    return AuthController.isErrorWrongPassword;
  }
  get errorInactiveAccount() {
    return AuthController.isInactiveAccount;
  }
  get checked() {
    return AuthController.isChecked;
  }
  get setChecked() {
    return AuthController.setRemember;
  }
  get isLoading() {
    return AuthController.isLoading;
  }

  created() {
    this.checkAuth();
  }

  checkAuth() {
    const accessToken = getToken();
    const expiredToken = getExpired();
    const formatExpireToken = this.$moment(expiredToken).format("DD");
    const currentdate = new Date();
    const datetime = currentdate.getDate();
    if (accessToken || datetime < Number(formatExpireToken)) {
      this.$router.push("/dashboard");
    }
  }

  passwordType = "password";
  iconType = "eye-slash";

  showPassword() {
    if (this.passwordType === "password" && this.iconType === "eye-slash") {
      this.passwordType = "text";
      this.iconType = "eye";
    } else {
      this.passwordType = "password";
      this.iconType = "eye-slash";
    }
  }

  submit() {
    const eventName = IS_SENDER_ACCOUNT
      ? "sender_login_button_click"
      : "login_button_click";
    dataLayer(eventName, {}, [
      "distincId",
      "browserVersion",
      "osVersion",
      "deviceType",
      "timestamp"
    ]);
    if (!this.email && !this.password) {
      this.invalidEmail = true;
      this.invalidPassword = true;
    } else if (!this.password) {
      this.invalidEmail = false;
      this.invalidPassword = true;
    } else {
      this.invalidEmail = false;
      this.invalidPassword = false;
      AuthController.sendLogin({
        email: this.email,
        password: this.password
      });
      this.handleErrorClose();
    }
  }

  get isSenderAccount() {
    return IS_SENDER_ACCOUNT;
  }
}
